import { FirebaseApp, initializeApp } from 'firebase/app';
import { addDoc, collection, Firestore, getDocs, getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA3AjNewAi2Y25_nq45kl2RlrUyJfdJMhw',
  authDomain: 'new-dao-jones.firebaseapp.com',
  projectId: 'new-dao-jones',
  storageBucket: 'new-dao-jones.appspot.com',
  messagingSenderId: '1065274829596',
  appId: '1:1065274829596:web:f612c870e12d69e9ec68d9',
  measurementId: 'G-8RGHRD8H8G',
};

class FirebaseService {
  app: FirebaseApp;
  db: Firestore;

  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.db = getFirestore(this.app);
  }

  async createRecord(collectionName: string, data: any) {
    return addDoc(collection(this.db, collectionName), data);
  }

  async getRecords(collectionName: string) {
    const querySnapshot = await getDocs(collection(this.db, collectionName));
    const result: any = [];
    querySnapshot.forEach(doc => {
      result.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return result;
  }
}

export const firebaseService = new FirebaseService();
