import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { provider } from '../services/walletConnect';

import { Menu } from '../components';
import QRCodeStyling from 'qr-code-styling';

import Logo from '../assets/images/logo.svg';

import Arrow1 from '../assets/images/arrow-1.svg';
import Arrow2 from '../assets/images/arrow-2.svg';
import Arrow3 from '../assets/images/arrow-3.svg';

import BookIcon from '../assets/images/book.svg';
import InstitutionIcon from '../assets/images/institution.svg';
import FireIcon from '../assets/images/fire.svg';

import { firebaseService } from '../services/firebase';

import './styles.scss';

interface Props { }
interface State {
  isOpenQR: boolean;
  isConnected: boolean;
}

export class Main extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpenQR: false,
      isConnected: false,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeydown);
    this.initWalletConnect();
  }

  initWalletConnect = () => {
    if (provider.connected) {
      this.setState({
        isConnected: true,
      });
    } else {
      provider.enable();
    }

    provider.on('connect', (err: any, payload: any) => {
      if (err) {
        toast.error(err.message);
      } else {
        this.onConnectWallet(provider.accounts[0]);
        this.setState({
          isConnected: true,
        });
      }
    });

    provider.on('disconnect', (code: any, reason: any) => {
      // provider.enable();
      window.location.reload();
    });

    provider.connector.on('display_uri', (err, payload) => {
      const uri = payload.params[0];
      this.renderQRCode(uri);
    });
  };

  onKeydown = (e: KeyboardEvent) => {
    const { isOpenQR } = this.state;
    if (e.code === 'Enter' && e.shiftKey && isOpenQR) {
      return this.setState({
        isOpenQR: false,
      });
    }

    if (e.code === 'Enter' && !isOpenQR) {
      return this.setState({
        isOpenQR: true,
      });
    }
  };

  onConnectWallet = async (address: string) => {
    console.log(address);
    try {
      const records = await firebaseService.getRecords('wallets');
      const record = records.find((entry: any) => entry.address === address);

      if (!record) {
        await firebaseService.createRecord('wallets', { address });
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  renderQRCode = (uri: string) => {
    const qrCode = new QRCodeStyling({
      width: 400,
      height: 400,
      type: 'svg',
      data: uri,
      dotsOptions: {
        type: 'dots',
        gradient: {
          type: 'linear',
          rotation: 90,
          colorStops: [
            { offset: 0.4, color: 'rgb(115,44,249)' },
            { offset: 0.9, color: 'rgb(88,207,252)' },
          ],
        },
      },
      cornersDotOptions: {
        color: 'rgb(0,255,139)',
      },
      cornersSquareOptions: {
        color: 'rgb(255,0,196)',
        type: 'extra-rounded',
      },
      backgroundOptions: {
        color: 'rgb(15,7,60)',
      },
    });
    const qrCodeElement = document.getElementById('qrcode') as any;
    qrCodeElement.innerHTML = '';
    qrCode.append(qrCodeElement);
  };

  render(): React.ReactNode {
    const { isConnected, isOpenQR } = this.state;
    const isOpenQRContainer = isOpenQR && !isConnected;

    return (
      <div className="h-screen w-screen flex">
        <Menu
          status={isConnected ? 'public connection' : 'disconnected'}
          onDisconnect={() => provider.connected && provider.disconnect()}
          items={[
            { route: '/', icon: InstitutionIcon },
            { route: '/', icon: FireIcon, link: 'https://depositor.jxndao.com/' },
            { route: '/', icon: BookIcon, link: 'https://docs.jxndao.com/' },
          ]}
          size={450}
        />

        <div className="h-full w-full flex flex-col">
          <div className="v-full flex-1 flex flex-col items-center direction-column text-center justify-center landing-container">
            <div className="qr-container">
              <img className={`logo ${isOpenQRContainer ? 'small' : ''}`} src={Logo} alt="logo" />
              <div id="qrcode" className={`qrcode ${isOpenQRContainer ? 'show' : ''}`}></div>
            </div>
            <div className="text-wrapper">
              <div className={`text-centent ${isOpenQRContainer ? '' : 'show'}`}>
                <p className="text-white text-7xl mt-4 landing-title">New DAO Jones</p>
                {isConnected ? (
                  <p className="text-white mt-8">
                    hit <b>ALT + Q</b> to disconnect
                  </p>
                ) : (
                  <p className="text-white mt-8">
                    hit <b>ENTER</b> to open the QR code member signup
                  </p>
                )}
              </div>
              <div className={`text-centent ${isOpenQRContainer ? 'show' : ''}`}>
                <p className="text-white mt-4">scan me, stuff happens</p>
                <p className="text-white mt-4">
                  hit <b>SHIFT + ENTER</b> to make disappear
                </p>
              </div>
            </div>
          </div>
          <div className={`footer ${isOpenQR || isConnected ? 'show' : ''}`}>
            <div className="footer-status">
              <div className="status-item text-white">
                <p>NDJ Roadmap</p>
              </div>
              <div className="status-item text-white">
                <p>stage: alpha</p>
                <div className="arrow text-white">
                  <img src={Arrow1} alt="" />
                  <p>we are here</p>
                </div>
              </div>
              <div className="status-item text-white">
                <p>stage: beta</p>
                <div className="arrow text-white">
                  <img src={Arrow2} alt="" />
                  <p>we think we’ll be here in 6 mos</p>
                </div>
              </div>
              <div className="status-item text-white">
                <p>stage: prod</p>
                <div className="arrow left text-white">
                  <img src={Arrow3} alt="" />
                  <p>feels like a yearish</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
