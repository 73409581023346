import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'chart.js/auto';
import './assets/styles/index.scss';
import { Main } from './pages';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Router>
    <Main />
    <ToastContainer autoClose={1000} position="top-right" hideProgressBar={true} closeOnClick={false} />
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
