import React, { useEffect, useState } from 'react';

import meneIcon from '../../assets/images/menu_icon.png';
import { MenuItem } from './menu';
export interface IMenuItem {
  route: string;
  icon?: any;
  text?: string;
  subItems?: IMenuItem[];
  link?: string;
}

interface Props {
  size: number;
  onDisconnect?: () => void;
  items: IMenuItem[];
  disabled?: boolean;
  status: string;
  ensName?: string;
}

export const Menu = ({ size, onDisconnect = () => {}, items, disabled = false, status, ensName = '' }: Props) => {
  const [focused, setFocused] = useState(true);

  const onKeydown = (e: KeyboardEvent) => {
    if (disabled) {
      return;
    }

    if (e.code === 'KeyQ' && e.ctrlKey) {
      e.preventDefault();
      onDisconnect();
    }

    if (e.code === 'Enter' && e.shiftKey) {
      return setFocused(true);
    }

    if (e.code === 'Enter') {
      return setFocused(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  }, [focused, disabled]);

  return (
    <div>
      <div
        className="flex items-center justify-center mr-8"
        style={{
          marginLeft: -size / 3,
          marginTop: -size / 3 - 40,
          width: size,
          minWidth: size,
          height: size,
          minHeight: size,
          position: 'relative',
        }}
      >
        <img className="h-full w-full" src={meneIcon} alt="" draggable={false} />
        <div className="absolute z-10">
          <p className="text-white mt-12 ml-4">
            {status}
            {!disabled && ensName ? ' as..' : ''}
          </p>
          {!disabled && ensName && <p className="text-white font-bold ml-4 z-10">{ensName}</p>}
        </div>
        <MenuItem onFocused={() => setFocused(true)} zIndex={0} items={items} size={size + 50} disabled={disabled} focused={focused} />
      </div>
    </div>
  );
};
